<template>
    <button
        :disabled="disabled || processing"
        @click="$emit('click', $event)"
        type="submit"
        class="inline-flex items-center justify-center rounded-md border border-transparent shadow-xs px-4 py-2 text-base font-medium text-white focus:outline-hidden focus:ring-2 focus:ring-offset-2 sm:col-start-2 sm:text-sm disabled:opacity-50"
        :class="{
            'flex cursor-not-allowed': processing,
            'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': !danger,
            'bg-red-600 hover:bg-red-700 focus:ring-red-500': danger,
        }"
    >
        <svg v-if="processing" class="animate-spin shrink-0 -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
        <template v-if="!processing">
            <template v-if="defaultText">
                {{ defaultText }}
            </template>
            <template v-else>
                <slot />
            </template>
        </template>
        <span v-else>{{ processingText }}</span>
    </button>
</template>

<script>
export default {
    props:{
        processing: {default:false, type:Boolean},
        defaultText: {type:String},
        processingText: {default:'Processing...', type:String},
        danger: {default:false, type:Boolean},
        disabled: { default:false, type: Boolean }
    }
};
</script>
